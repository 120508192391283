import React from 'react'
import MediaQuery from 'react-responsive'
import Fade from 'react-reveal/Fade'

// import Divider from 'src/components/Divider'
import ValueProp from './ValueProp'
import './style.scss'

const HowToOrder = ({ watName1, watName2, isFanPage }) => {
  const titleNameMain = (name, name2, isMb) => {
    // console.log(name, name2, isMb)
    if (name2 != null && name2?.length > 0) {
      return (
        <>
          <h1>
            ร้านพวงหรีด{isMb && <br />}
            {name}
            <br />
            {name2} No.1
          </h1>
        </>
      )
    }
    return (
      <h1>
        ร้านพวงหรีด{isMb && <br />}
        {name} No.1
      </h1>
    )
  }

  return (
    <div className="how-to-order">
      <div className="container mobile">
        <Fade delay={100}>
          <div className="header-title">
            {/* <h1>
              ร้านพวงหรีด <br />
              {watName1} No.1
            </h1> */}
            {titleNameMain(watName1, watName2, true)}
          </div>
        </Fade>
        <div className="body-box">
          <div className="value-prop-wrapper">
            <ValueProp />
          </div>
        </div>
      </div>

      <div className="desktop-bg">
        <div className="container desktop">
          <div className="header-title">
            <div className="header-wrapper">
              <img src="https://d3u85r51ph21nf.cloudfront.net/premium/static/no1_badge.png" />
              <div className="title-box">
                <MediaQuery minDeviceWidth={767}>
                  {/* <h1>ร้านพวงหรีดประจำ{watName1} No.1</h1> */}
                  {titleNameMain(watName1, watName2, false)}
                </MediaQuery>
                <p>
                  มอบความรักและความอาลัย ผ่านพวงหรีดดอกไม้สด <span>‘แทนใจ’</span>
                </p>
              </div>
            </div>
            <div className="body-box">
              <div className="value-prop-wrapper">
                <ValueProp />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="hero-reed-img">
        <div className="container">
          <img
            className="img-responsive"
            src="https://d3u85r51ph21nf.cloudfront.net/static/hero_reed_mobile_v2.png"
            alt="สั่งพวงหรีดแอดไลน์ @tanjai.co"
          />
        </div>
      </div>
    </div>
  )
}

export default HowToOrder
