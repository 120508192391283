import React from 'react'
import Zoom from 'react-reveal/Zoom'

import './style.scss'

const ValueProp = props => {
  return (
    <div>
      <Zoom delay={100}>
        <div className="value-prop-item">
          <div className="icon person">
            <img
              src="https://d3u85r51ph21nf.cloudfront.net/static/icon_prop_5.png"
              alt="มีเจ้าหน้าที่คอยให้คำปรึกษา"
            />
          </div>
          <div className="text">
            <p>มีเจ้าหน้าที่คอยให้คำปรึกษา</p>
          </div>
        </div>
      </Zoom>
      <Zoom delay={300}>
        <div className="value-prop-item">
          <div className="icon badge">
            <img
              src="https://d3u85r51ph21nf.cloudfront.net/static/icon_prop_2.png"
              alt="พวงหรีดคุณภาพ ปราณีต"
            />
          </div>
          <div className="text">
            <p>พวงหรีดคุณภาพ ปราณีต</p>
          </div>
        </div>
      </Zoom>
      <Zoom delay={500}>
        <div className="value-prop-item">
          <div className="icon camera">
            <img
              src="https://d3u85r51ph21nf.cloudfront.net/static/icon_prop_3.png"
              alt="ส่งถึงที่ ตรงเวลา มีรูปยืนยัน"
            />
          </div>
          <div className="text">
            <p>
              ส่งถึงที่ ตรงเวลา <b>มีรูปยืนยัน</b>
            </p>
          </div>
        </div>
      </Zoom>
      <Zoom delay={700}>
        <div className="value-prop-item">
          <div className="icon vat">
            <img
              src="https://d3u85r51ph21nf.cloudfront.net/static/icon_prop_1.png"
              alt="ออกใบกำกับภาษีได้"
            />
          </div>
          <div className="text">
            <p>ออกใบกำกับภาษีได้</p>
          </div>
        </div>
      </Zoom>
      <Zoom delay={900}>
        <div className="value-prop-item">
          <div className="icon secure">
            <img
              src="https://d3u85r51ph21nf.cloudfront.net/static/icon_prop_4.png"
              alt="ออกใบกำกับภาษีได้"
              height="34"
              style={{ paddingBottom: '5px' }}
            />
          </div>
          <div className="text">
            <p>
              ไม่ถึงงาน <b>ยินดีคืนเงิน</b>
            </p>
          </div>
        </div>
      </Zoom>
    </div>
  )
}

export default ValueProp
