import React from 'react'
import Fade from 'react-reveal/Fade'
import HeadShake from 'react-reveal/HeadShake'
import LazyLoad from 'react-lazyload'

import Divider from 'src/components/Divider'
import GoogleMap from 'src/components/GoogleMap'

import { getTelFormatWithCountryCode } from 'static/utils'
import { TEL } from 'static/config/variables'

import './style.scss'

const Contact = () => {
  return (
    <div className="contact">
      <div className="container">
        <Fade delay={300}>
          <div className="contact-title">
            <h3>ติดต่อเรา</h3>
            {/* <Divider /> */}
          </div>
        </Fade>
        <LazyLoad>
          <div className="contact-body">
            <Fade delay={300}>
              <div className="contact-line-oa">
                <h4>@tanjai.co</h4>
                <a
                  href="https://lin.ee/6Bs20SI?tanjai=A4"
                  target="_blank"
                  rel="noopener noreferrer"
                  alt="@tanjai.co"
                  id="m-contact-line"
                  title="@tanjai.co"
                >
                  <img src="https://d3u85r51ph21nf.cloudfront.net/static/qr.png" alt="@tanjai.co" />
                </a>
                <div className="line-oa-button">
                  <p className="desktop green">ตอบเร็ว มีเจ้าหน้าที่ดูแลจนกว่าพวงหรีดถึงงาน</p>
                  <HeadShake delay={750}>
                    <a
                      href="https://lin.ee/6Bs20SI?tanjai=A5"
                      target="_blank"
                      rel="noopener noreferrer"
                      alt="@tanjai.co"
                      id="d-contact-line"
                      title="@tanjai.co"
                    >
                      คลิก Add line เพื่อสั่งซื้อ
                    </a>
                  </HeadShake>
                </div>
                <p>พิมพ์ @tanjai.co หรือสแกน QR Code เพื่อติดต่อเรา</p>
              </div>
            </Fade>
            <Divider className="secondary d-md-none" />
            <div className="contact-other">
              <Fade delay={300}>
                <div className="icon-wrapper">
                  {/* <div className="icon-item">
                    <a href={`tel:${getTelFormatWithCountryCode(TEL)}`} title={TEL}>
                      <div>
                        <img
                          src="https://d3u85r51ph21nf.cloudfront.net/static/contact_call.png"
                          alt={TEL}
                        />
                      </div>
                      <div>
                        <p>{TEL}</p>
                      </div>
                    </a>
                  </div> */}
                  <div className="icon-item">
                    <div>
                      <img
                        src="https://d3u85r51ph21nf.cloudfront.net/static/contact_email.png"
                        alt="tanjai.bkk@gmail.com"
                      />
                    </div>
                    <div>
                      <p>tanjai.bkk@gmail.com</p>
                    </div>
                  </div>
                  <div className="icon-item">
                    <div>
                      <img
                        src="https://d3u85r51ph21nf.cloudfront.net/static/contact_location.png"
                        alt="Office"
                      />
                    </div>
                    <div>
                      <p>
                        <span>
                          <b>สำนักงานใหญ่</b>
                        </span>
                        <br />
                        101 True Digital Park 5th Floor, Sukhumvit Rd. Bang Chak, Prakanong, Bangkok
                        10260
                      </p>
                    </div>
                  </div>
                </div>
              </Fade>
              <Fade delay={300}>
                <div className="google-map">
                  <GoogleMap
                    lat={13.685302}
                    lng={100.611031}
                    wat="สำนักงานใหญ่"
                    address="101 True Digital Park 5th Floor, Sukhumvit Rd. Bang Chak, Prakanong, Bangkok 10260"
                  />
                </div>
              </Fade>
            </div>
          </div>
        </LazyLoad>
      </div>
    </div>
  )
}

export default Contact
