import React from 'react'
import ReactMarkdown from 'react-markdown'
import Divider from 'src/components/Divider'
import PurchaseButtonGroup from 'src/components/PurchaseButtonGroup'

import ModalFAQ from './ModalFAQ'
import HowToOrder from './HowToOrder'
import './style.scss'

import { Tabs } from 'antd'

const { TabPane } = Tabs

function callback(key) {
  // console.log(key)
}

const Desktop = props => {
  const getTag = (category, size) => {
    if (category === 'P') return <div className="tag small">พรีเมียม</div>
    else if (category === 'F') return <div className="tag xs">size {size}</div>
    else return ''
  }

  const { onCloseModal, selectedProduct, watName, isShowTel, onToggleTel, isFanPage } = props
  return (
    <div className="modal-wrapper desktop container">
      <div className="close-box">
        <button onClick={onCloseModal}>
          <img
            src="https://d3u85r51ph21nf.cloudfront.net/static/close_gold.png"
            alt="ปิด"
            width="28"
            height="28"
          />
        </button>
      </div>
      <div className="left-box">
        <div className="modal-image">
          <img
            src={selectedProduct.image ? selectedProduct.image : selectedProduct.imageS3}
            alt={selectedProduct.id}
          />
        </div>
        <Divider className="secondary" />
        <div className="price-box">
          <div className="price-wrapper">
            <div>
              <p>{selectedProduct.id}</p>
              {getTag(selectedProduct.category, selectedProduct.size)}
            </div>
            <p className="price">฿{Number(selectedProduct.price).toLocaleString()}</p>
          </div>
          {selectedProduct.m && (
            <div className="price-wrapper border-left">
              <div>
                <p>{selectedProduct.m.id}</p>
                {getTag(selectedProduct.category, selectedProduct.m.size)}
              </div>
              <p className="price">฿{Number(selectedProduct.m.price).toLocaleString()}</p>
            </div>
          )}
        </div>
      </div>
      <div className="right-box">
        <h4>{selectedProduct.id}</h4>
        <p className="sub-title">พวงหรีด{watName}</p>
        <div style={{ marginBottom: 20 }}>
          <Tabs onChange={callback} type="card">
            <TabPane tab="ข้อมูลสินค้า" key="1">
              <div className="product-desc">
                <ReactMarkdown source={selectedProduct.description} />
              </div>
            </TabPane>
            <TabPane tab="วิธีการสั่งซื้อ" key="how-to-order">
              <div className="modal-body">
                <HowToOrder />
              </div>
            </TabPane>
            <TabPane tab="คำถามที่พบบ่อย" key="faq-d">
              <div className="modal-body">
                <ModalFAQ className="no-border" />
              </div>
            </TabPane>
          </Tabs>
        </div>
        <PurchaseButtonGroup
          buttonId={`D-${selectedProduct.category}_${selectedProduct.id}`}
          isShowCall={true}
          isShowTel={isShowTel}
          onToggleTel={onToggleTel}
          watName={watName}
          isFanPage={isFanPage}
          classNameFanRemark="modal-fan-remark"
        />
      </div>
    </div>
  )
}

export default Desktop

Desktop.defaultProps = {
  isShowTel: false,
  onToggleTel: () => {},
  onCloseModal: () => {},
  selectedProduct: {},
  watName: ''
}
