import React from 'react'
import styled from 'styled-components'
import Divider from 'src/components/Divider'

const ModalFAQ = props => {
  return (
    <div>
      <Text>
        {`Q : พื้นที่ห่างไกลจัดส่งหรือไม่ มีค่าส่งอย่างไร`} <br />
        <span>{`A : ‘แทนใจ’ รับจัดส่งพวงหรีดทั่วประเทศ หากเป็นพื้นที่ห่างไกล โปรดคอนเฟิร์มการสั่งซื้อในช่วงเช้า โดยค่าจัดส่งเริ่มต้นที่ 100.- คิดตามระยะทางจริง`}</span>
      </Text>
      <Divider className="secondary" />
      <Text>
        {`Q : สั่งพวงหรีดด่วนหลัง 16.00 - 18.00 น. ส่งทันหรือไม่`} <br />
        <span>{`A : เรามีบริการส่งด่วนหลัง 16.00 น. กรุณาทักไลน์ @tanjai.co เพื่อสอบถามเจ้าหน้าที่ อาจมีค่าบริการเพิ่มเติม โดยวัดใหญ่ในตัวเมืองส่งรวดเร็ว ถึงไว หากเป็นพื้นที่ห่างไกล อาจจำเป็นต้องจัดส่งวันถัดไป`}</span>
      </Text>
      <Divider className="secondary" />
      <Text>
        {`Q : สั่งซื้อพวงหรีดวันนี้ จัดส่งวันที่กำหนดได้หรือไม่`} <br />
        <span>{`A : ท่านสามารถสั่งซื้อล่วงหน้าได้ แทนใจจะแจ้งเตือนท่านในวันจัดส่ง โดยส่งรูปยืนยันก่อนจัดส่งและรูปยืนยันเมื่อถึงงาน`}</span>
      </Text>
      <Divider className="secondary" />
      <Text>
        {`Q : แบบพวงหรีดจะเหมือนที่เลือกหรือไม่`} <br />
        <span>{`A : สำหรับวัดใหญ่ในเมือง ท่านจะได้รับพวงหรีดตามแบบ ส่วนวัดในพื้นที่ห่างไกล แบบพวงหรีดอาจมีการเปลี่ยนแปลง โดยเจ้าหน้าที่จะแจ้งท่านก่อนการชำระเงิน`}</span>
      </Text>
    </div>
  )
}

ModalFAQ.defaultProps = {
  watName: ''
}

export default ModalFAQ

const Text = styled.p`
  margin-top: 15px;
  margin-bottom: 8px;
  span {
    font-weight: 300;
  }
`
