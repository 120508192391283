
import React, { Component } from 'react'
import styled from 'styled-components'
import { GOOGLE_MAP_KEY } from 'static/config'
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react'

const containerStyle = {
  position: 'relative',
  width: '100%',
  height: '100%'
}

const style = {
  width: '95%',
  height: '500px',
  margin: '0 auto'
}

class MapView extends Component {
  constructor() {
    super()
    this.state = {
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
      mapLoaded: false
    }
  }

  onMarkerClick = (props, marker, e) => {
    this.setState(prevState => ({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true
    }))
  }

  onClose = () => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null
      })
    }
  }

  onMarkerMounted = element => {
    this.onMarkerClick(element.props, element.marker, element)
  }

  handleMapIdle = () => {
    this.setState({
      mapLoaded: true
    })
  }

  render() {
    const { lat, lng, address, wat } = this.props
    return (
      <Map
        google={this.props.google}
        containerStyle={containerStyle}
        style={style}
        initialCenter={{
          lat: lat,
          lng: lng
        }}
        zoom={this.props.zoom}
        onIdle={this.handleMapIdle}
      >
        {this.state.mapLoaded && <Marker ref={this.onMarkerMounted} onClick={this.onMarkerClick} />}
        <InfoWindow
          marker={this.state.activeMarker}
          visible={this.state.showingInfoWindow}
          onClose={this.onClose}
        >
          <div>
            <Title>
              <b>{wat}</b>
            </Title>
            <AddressText>{address}</AddressText>
            <a
              href={`https://g.page/TrueDigitalPark?share`}
              target="_blank"
              rel="noopener noreferrer"
              title="google map"
            >
              เปิดใน google map
            </a>
          </div>
        </InfoWindow>
      </Map>
    )
  }
}

export default GoogleApiWrapper({
  apiKey: GOOGLE_MAP_KEY
})(MapView)

const Title = styled.p`
  color: #000;
  margin-bottom: 0;
`
const AddressText = styled.p`
  color: #000;
  margin: 0;
`
