export const FAN_IMG_LIST = [
  {
    src: 'https://d3u85r51ph21nf.cloudfront.net/reviews/fan/002-450x450-min.jpg',
    alt: 'ตัวอย่างพวงหรีดพัดลม วัดกรอกยายชา จังหวัดระยอง'
  },
  {
    src: 'https://d3u85r51ph21nf.cloudfront.net/reviews/fan/030-450x450-min.jpg',
    alt: 'ตัวอย่างพวงหรีดพัดลม วัดพุทธมงคลนิมิต จังหวัดภูเก็ต'
  },
  {
    src: 'https://d3u85r51ph21nf.cloudfront.net/reviews/fan/033-450x450-min.jpg',
    alt: 'ตัวอย่างพวงหรีดพัดลม วัดท่ามะปราง จังหวัดพิษณุโลก'
  },
  {
    src: 'https://d3u85r51ph21nf.cloudfront.net/reviews/fan/041-450x450-min.jpg',
    alt: 'ตัวอย่างพวงหรีดพัดลม วัดเสมียนนารี จังหวัดกรุงเทพ'
  },
  {
    src: 'https://d3u85r51ph21nf.cloudfront.net/reviews/fan/045-450x450-min.jpg',
    alt: 'ตัวอย่างพวงหรีดพัดลม จังหวัดจันทบุรี'
  }
]
