import React, { Component } from 'react'

import { TEL } from 'static/config/variables'
import FAQItem from './FAQItem'

import './style.scss'

export default class CustomFAQ extends Component {
  render() {
    const { watName, faq } = this.props
    return (
      <div
        className="container"
        itemScope
        itemProp="mainEntity"
        itemType="https://schema.org/Question"
      >
        {faq && faq.length !== 0 ? (
          faq.map((item, index) => {
            return (
              <FAQItem key={index} title={`Q : ${item.question}`} answer={`A : ${item.answer}`} />
            )
          })
        ) : (
          <>
            <FAQItem
              title={`Q : คำอาลัยบนพวงหรีด ต้องเขียนอย่างไร`}
              answer={`A : เราจะมีเจ้าหน้าที่ให้คำแนะนำ โดยส่วนใหญ่ใช้คำว่า อาลัยยิ่ง...... ด้วยรักและเคารพ......`}
            />
            <FAQItem
              title={`Q : ถ้าต้องการให้ส่งพวงหรีด${watName ? `ที่${watName}` : ''} ต้องทำอย่างไร`}
              answer={`A : กดแอดไลน์ @tanjai.co เพื่อรับการบริการจากเจ้าหน้าที่ทันที หรือโทร ${TEL}`}
            />
            <FAQItem
              title={`Q : ข้อมูลที่จำเป็นในการสั่งพวงหรีด${watName}`}
              answer={`A : 1. แบบพวงหรีดที่ต้องการ 2. ข้อความบนป้าย 1-2 บรรทัด 3. แจ้งข้อมูล ชื่อผู้วายชนม์ วัด และศาลา เพื่อส่งพวงหรีดได้ถูกต้อง`}
            />
            <FAQItem
              title={`Q : พื้นที่ห่างไกลจัดส่งหรือไม่ มีค่าส่งอย่างไร`}
              answer={`A : ‘แทนใจ’ รับจัดส่งพวงหรีดทั่วประเทศ หากเป็นพื้นที่ห่างไกล โปรดคอนเฟิร์มการสั่งซื้อในช่วงเช้า โดยค่าจัดส่งเริ่มต้นที่ 100.- คิดตามระยะทางจริง`}
            />
            <FAQItem
              title={`Q : สั่งพวงหรีดด่วนหลัง 16.00 - 18.00 น. ส่งทันหรือไม่`}
              answer={`A : เรามีบริการส่งด่วนหลัง 16.00 น. กรุณาทักไลน์ @tanjai.co เพื่อสอบถามเจ้าหน้าที่ อาจมีค่าบริการเพิ่มเติม โดยวัดใหญ่ในตัวเมืองส่งรวดเร็ว ถึงไว หากเป็นพื้นที่ห่างไกล อาจจำเป็นต้องจัดส่งวันถัดไป`}
            />
            <FAQItem
              title={`Q : สั่งซื้อพวงหรีดวันนี้จัดส่งวันที่กำหนดได้หรือไม่`}
              answer={`A : ท่านสามารถสั่งซื้อล่วงหน้าได้ แทนใจจะแจ้งเตือนท่านในวันจัดส่ง โดยส่งรูปยืนยันก่อนจัดส่งและส่งถึงงาน`}
            />
            <FAQItem
              title={`Q : แบบพวงหรีดจะเหมือนที่เลือกหรือไม่`}
              answer={`A : สำหรับวัดใหญ่ในเมือง ท่านจะได้รับพวงหรีดตามแบบ ส่วนวัดในพื้นที่ห่างไกล แบบพวงหรีดอาจมีการเปลี่ยนแปลง โดยเจ้าหน้าที่จะแจ้งท่านก่อนการชำระเงิน`}
            />
          </>
        )}
      </div>
    )
  }
}

CustomFAQ.defaultProps = {
  watName: ''
}
