import React, { Component } from 'react'
import styled from 'styled-components'

const Box = styled.div`
  padding: 15px;
`

export default class HowToOrder extends Component {
  render() {
    return (
      <Box>
        <img
          className="img-responsive"
          src="https://d3u85r51ph21nf.cloudfront.net/static/how_to_modal.png"
        />
      </Box>
    )
  }
}
