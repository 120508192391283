import React, { Component } from 'react'
import Zoom from 'react-reveal/Zoom'
import Slider from 'react-slick'
import { Button } from 'antd'
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'

import Divider from 'src/components/Divider'
import CustomModal from 'src/components/CustomModal'
import PurchaseButtonGroup from 'src/components/PurchaseButtonGroup'
// import PurchaseButtonDesktop from 'src/components/PurchaseButtonDesktop'
import { colorDefalut } from '../../../static/utils'
import './style.scss'
import ProductButtonColor from '../ProductButtonColor'

export default class ProductFan extends Component {
  targetRef = React.createRef()
  targetElement = null

  state = {
    isModalOpen: false,
    isShowTel: false,
    selectedProduct: {},
    focusColorA: 'other',
    runningRander: 0,
    hover: false,
    hoverColor: ''
  }

  componentDidMount() {
    this.targetElement = this.targetRef.current
  }

  componentWillUnmount() {
    clearAllBodyScrollLocks()
  }

  onOpenModal = product => {
    let selectedProduct
    if (product.length > 1) {
      selectedProduct = {
        ...product[0],
        m: {
          id: product[1].id,
          price: product[1].price,
          size: product[1].size,
          description: product[1].description,
          descMobile: product[1].descMobile
        }
      }
    } else {
      selectedProduct = {
        ...product[0]
      }
    }
    this.setState({ isModalOpen: true, selectedProduct })
    disableBodyScroll(this.targetElement)
  }

  onCloseModal = () => {
    this.setState({ isModalOpen: false, isShowTel: false })
    enableBodyScroll(this.targetElement)
  }

  groupBy = (objectArray, property) => {
    const { isFanPage } = this.props
    return objectArray.reduce(function(total, obj) {
      let key
      if (isFanPage) {
        key = obj.fanBangkok.position
      } else {
        key = obj[property]
      }

      if (!total[key]) {
        total[key] = []
      }
      total[key].push(obj)
      return total
    }, {})
  }

  getProducts = () => {
    const positionGroup = this.groupBy(this.props.products, 'position')
    const keyArray = Object.keys(positionGroup)
    let fanProducts = keyArray.map(key => {
      return positionGroup[key].sort((a, _) => {
        if (a.id.includes('F1')) return -1
        else return 1
      })
    })
    return fanProducts
  }

  handleMouseOver = color => {
    this.setState({ hover: true })
    this.setState({ hoverColor: color })
  }
  handleMouseLeave = () => {
    this.setState({ hover: false })
    this.setState({ hoverColor: '' })
  }

  getColorSelect = () => {
    const colorInProduct = this.props.products || []

    let colorArray = []
    for (let index = 0; index < colorInProduct.length; index++) {
      const element = colorInProduct[index].colorTone
      for (let index = 0; index < element.length; index++) {
        const colortone = element[index]
        colorArray.push(colortone)
      }
    }

    const colorSelect = ['other'].concat(colorArray) || []
    let colorArrayOutput = []
    for (let index = 0; index < colorSelect.length; index++) {
      const color = colorSelect[index]
      if (!colorDefalut[color]) {
        colorArrayOutput.push(colorDefalut['other'])
        continue
      }
      colorArrayOutput.push(colorDefalut[color])
    }

    // Create unique color obj
    const result = colorArrayOutput.reduce((unique, o) => {
      if (!unique.some(obj => obj.nameEn === o.nameEn)) {
        unique.push(o)
      }
      return unique
    }, [])
    return result
  }

  findColorByBase = (baseColor, colorArray) => {
    let output = false
    for (let index = 0; index < colorArray.length; index++) {
      const element = colorArray[index]
      if (colorDefalut[element] && colorDefalut[element].tone.includes(baseColor)) {
        output = true
      }
    }
    return output
  }

  onToggleTel = () => {
    this.setState({
      isShowTel: !this.state.isShowTel
    })
  }

  getSlider = products => {
    if (products) {
      if (products.length !== 0) {
        const settings = {
          dots: true,
          infinite: false,
          speed: 250,
          slidesToShow: products.length >= 4 ? 4 : products.length,
          slidesToScroll: 1,
          arrows: true,
          autoplay: false,
          customPaging: i => <div>{i + 1}</div>,
          pauseOnHover: true,
          responsive: [
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2
              }
            }
          ]
        }
        return (
          <Slider {...settings}>
            {this.getProducts().map((product, index) => {
              const [s, m] = product
              return (
                <Zoom key={index}>
                  <a
                    role="presentation"
                    className="pf-product-item-wrapper product-view"
                    onClick={() => this.onOpenModal(product)}
                    onKeyDown={() => this.onOpenModal(product)}
                    id={s.id}
                  >
                    <div className="pf-product-item">
                      <div className="pf-product-img">
                        <img src={s.imageS3 ? s.imageS3 : s.image} alt={s.id} />
                      </div>
                      <Divider className="secondary" />
                      <div className="pf-product-detail-wrapper">
                        <div className="pf-product-detail">
                          <small>{s.id}</small>
                          <p>฿{Number(s.price).toLocaleString()}</p>
                          <div className="tag xs">size {s.size}</div>
                        </div>
                        {m && (
                          <>
                            <div className="divider-secondary" />
                            <div className="pf-product-detail">
                              <small>{m.id}</small>
                              <p>฿{Number(m.price).toLocaleString()}</p>
                              <div className="tag xs">size {m.size}</div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </a>
                </Zoom>
              )
            })}
          </Slider>
        )
      }
    } else {
      return ''
    }
  }

  renderProduct = (product, index) => {
    const [s, m] = product
    return (
      <Zoom key={this.state.runningRander + index + product[0].position} delay={200}>
        <a
          role="presentation"
          className="pf-product-item-wrapper product-view"
          onClick={() => this.onOpenModal(product)}
          onKeyDown={() => this.onOpenModal(product)}
          id={s.id}
        >
          <div className="pf-product-item">
            <div className="pf-product-img">
              <img src={s.imageS3 ? s.imageS3 : s.image} alt={s.id} />
            </div>
            {/* <Divider className="secondary" /> */}
            <div className="pf-product-detail-wrapper">
              <div className="pf-product-detail">
                <small>{s.id}</small>
                <p>฿{Number(s.price).toLocaleString()}</p>
                <div className="tag xs">size {s.size}</div>
              </div>
              {m && (
                <>
                  <div className="divider-secondary" />
                  <div className="pf-product-detail">
                    <small>{m.id}</small>
                    <p>฿{Number(m.price).toLocaleString()}</p>
                    <div className="tag xs">size {m.size}</div>
                  </div>
                </>
              )}
            </div>
            <div className="see-detail-button" onClick={() => this.onOpenModal(product)}>
              <img
                className="img-responsive"
                src="https://d3u85r51ph21nf.cloudfront.net/static/icon_cursor.png"
                alt="คลิก"
              />
              <p>ดูรายละเอียด</p>
            </div>
          </div>
        </a>
      </Zoom>
    )
  }
  setColor = color => {
    this.setState({
      focusColorA: color,
      runningRander: this.state.runningRander + Math.floor(Math.random() * 100 + 1)
    })
  }

  render() {
    const { isModalOpen, isShowTel, selectedProduct } = this.state
    const { watName, products, colorSelect } = this.props
    return (
      <div className="product-fan" id="product-f">
        <div className="container">
          <div className="line-title" />
          <div className="category-title">
            <h4>พวงหรีดพัดลม</h4>
          </div>
          {/* <div className="buttom-color-wrapper">
            {products &&
              products.length > 10 &&
              this.getColorSelect().map(value => {
                return (
                  <Button
                    className="buttom-color-product"
                    onMouseOver={() => {
                      this.handleMouseOver(value.nameEn)
                    }}
                    onMouseLeave={this.handleMouseLeave}
                    style={{
                      height: 'auto',
                      background: this.state.focusColorA == value.nameEn && value.colorHex,
                      borderRadius: '10px',
                      margin: '4px',
                      boxSizing: 'border-box',
                      boxShadow:
                        this.state.focusColorA == value.nameEn
                          ? '0px 5px 5px rgba(0, 0, 0, 0.25)'
                          : '0px 1px 2px rgba(0, 0, 0, 0.25)'
                    }}
                    onClick={() => {
                      this.setColor(value.nameEn)
                    }}
                  >
                    {value.nameTh}
                  </Button>
                )
              })}
          </div> */}
          {/* <div className="note-product-wrapper">
            <p>*จำนวน สี ขนาด และชนิดดอกไม้มีการผันแปรตามฤดู</p>
          </div> */}
          <div className="slider-product-wrapper">
            {products && colorSelect == 'other'
              ? this.getProducts().map((product, index) => this.renderProduct(product, index))
              : products &&
                this.getProducts()
                  .filter(e => this.findColorByBase(colorSelect, e[0].colorTone))
                  .map((product, index) => this.renderProduct(product, index))}
          </div>
          <div style={{ marginTop: 5 }} id={`purchase-product-f`}>
            {/* <div className="hide-lg"> */}
            <div className="show-lg">
              <PurchaseButtonGroup
                watName={watName}
                isFanPage={true}
                buttonId="below-fan-product__mobile"
              />
            </div>
            {/* </div>
            <div className="show-lg">
              <PurchaseButtonDesktop isFanPage={true} buttonId="below-fan-product__desktop" />
            </div> */}
          </div>
        </div>
        {isModalOpen && (
          <CustomModal
            ref={this.targetRef}
            isModalOpen={isModalOpen}
            onCloseModal={this.onCloseModal}
            selectedProduct={selectedProduct}
            watName={watName}
            onToggleTel={this.onToggleTel}
            isShowTel={isShowTel}
            isFanPage={true}
          />
        )}
      </div>
    )
  }
}
