import React, { useState, useEffect, useCallback } from 'react'
import propTypes from 'prop-types'
import { Button } from 'antd'
import { colorDefalut } from '../../../static/utils'
import './style.scss'
import Scroll from 'react-scroll'
import Fade from 'react-reveal/Fade'
import Tada from 'react-reveal/Tada'
const scroller = Scroll.scroller

const ProductButtonColor = props => {
  const { colorProduct, onSelect, id, onClick, isStickyMenuMobile } = props
  const [hover, setHover] = useState(false)
  const [hoverColor, setHoverColor] = useState('')
  const [selectColor, setSelectColor] = useState('other')
  const [stickyShow, setStickyShow] = useState(true)
  const [isMobile, setIsMobile] = useState(false)
  const [menuMobile, setMenuMobile] = useState(undefined)

  useEffect(() => {
    if (typeof window !== 'undefined' && window.innerWidth <= 767) {
      // Mobile
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }

    // validateSticky()
  }, [])

  const mapProduct = cat => {
    let output = 'product-a'
    switch (cat) {
      case 'cat-a':
        output = 'product-a'
        break
      case 'cat-b':
        output = 'product-b'
        break
      case 'cat-p':
        output = 'product-p'
        break
      case 'cat-f':
        output = 'product-f'
        break
      default:
        break
    }

    return output
  }

  // const validateSticky = () => {
  //   var header = document.getElementById(mapProduct(id))
  //   var bottom = document.getElementById(mapProduct(id))
  //   var stickyTop = header.offsetTop - 70
  //   var stickyBottom = bottom.offsetHeight + header.offsetTop - 70
  //   var offset = window.pageYOffset
  //   console.log('active', window.pageYOffset >= stickyTop && window.pageYOffset <= stickyBottom)
  //   // if (window.pageYOffset >= stickyTop && window.pageYOffset <= stickyBottom) {
  //   //   setStickyShow(true)
  //   // } else {
  //   //   setStickyShow(false)
  //   // }
  // }

  const handleMouseOver = color => {
    setHover(true)
    setHoverColor(color)
  }

  const handleMouseLeave = () => {
    setHover(false)
    setHoverColor('')
  }

  const ColorToneObj = {
    other: {
      nameEn: 'all',
      nameTh: 'ทั้งหมด'
    },
    hot: {
      nameEn: 'hot',
      nameTh: 'สีร้อน',
      color: ['#FAFF00', '#FFAC30', '#FF3030']
    },
    cool: {
      nameEn: 'cool',
      nameTh: 'สีเย็น',
      color: ['#5BBAFF', '#DFACFF', '#FFACCF']
    },
    smart: {
      nameEn: 'smart',
      nameTh: 'สีสุภาพ',
      color: ['#FFFFFF', '#48A111', '#FFF2DA']
    }
  }

  const validateColorToneButtom = value => {
    if (value === 'other') return <>{ColorToneObj[value].nameTh}</>
    return (
      <span style={{ display: 'inline-flex' }}>
        <div style={{ position: 'initial', height: '21px' }}>โทน </div>{' '}
        <div style={{ position: 'relative', display: 'flex', width: '40px' }}>
          <div
            style={{
              position: 'absolute',
              width: '22.22px',
              height: '22.22px',
              background: ColorToneObj[value].color[0],
              border: '1px solid #EDEDED',
              boxSizing: 'border-box',
              borderRadius: '15px',
              left: '5px'
            }}
          />
          <div
            style={{
              position: 'absolute',
              width: '22.22px',
              height: '22.22px',
              background: ColorToneObj[value].color[1],
              border: '1px solid #EDEDED',
              boxSizing: 'border-box',
              borderRadius: '15px',
              left: '15px'
            }}
          />
          <div
            style={{
              position: 'absolute ',
              width: '22.22px',
              height: '22.22px',
              background: ColorToneObj[value].color[2],
              border: '1px solid #EDEDED',
              boxSizing: 'border-box',
              borderRadius: '15px',
              left: '25px'
            }}
          />
        </div>
      </span>
    )
  }

  if (isStickyMenuMobile) {
    return (
      <Fade duration={1000}>
        <div
          className={`.buttom-color-wrapper-mobile ${isStickyMenuMobile && 'sticky-color'}`}
          style={{ display: 'flex' }}
          id={`color-${id}`}
        >
          {stickyShow &&
            colorProduct.map(value => {
              return (
                <Tada when={selectColor == value} key={`${'buttom-color' + value + 'tada'}`}>
                  <Button
                    key={`${'buttom-color' + value}`}
                    className="buttom-color-product-mobile"
                    onMouseOver={() => {
                      handleMouseOver(value.nameEn || value)
                    }}
                    onMouseLeave={handleMouseLeave}
                    style={{
                      fontFamily: 'Sarabun',
                      fontStyle: 'normal',
                      fontWeight: 'normal',
                      height: 'auto',
                      width: 'auto',
                      justifyContent: 'center',
                      background: selectColor == value && '#B2A78C',
                      borderRadius: '10px',
                      margin: '4px',
                      boxSizing: 'border-box',
                      display: 'flex',
                      boxShadow: selectColor == value && '#B2A78C',
                      color: selectColor == value && '#FFFFFF',
                      borderColor: selectColor == value && '#B2A78C',
                      boxShadow: selectColor == value && '0px 4px 3px rgba(0, 0, 0, 0.25)'
                    }}
                    onClick={() => {
                      setSelectColor(value.nameEn || value)
                      onSelect(value.nameEn || value)
                      onClick(id)
                      const offset = document.getElementById('mobile-menu').offsetHeight
                      setTimeout(() => {
                        scroller.scrollTo(mapProduct(id), {
                          duration: 1000,
                          delay: 100,
                          smooth: true,
                          offset: -offset
                        })
                      }, 100)
                    }}
                  >
                    {validateColorToneButtom(value)}
                  </Button>
                </Tada>
              )
            })}
        </div>
      </Fade>
    )
  }
  return (
    <div
      className={`.buttom-color-wrapper-mobile ${isStickyMenuMobile && 'sticky-color'}`}
      id={`color-${id}`}
    >
      {colorProduct.map(value => {
        return (
          <Button
            key={`${'buttom-color' + value + '2'}`}
            className="buttom-color-product"
            onMouseOver={() => {
              handleMouseOver(value.nameEn)
            }}
            onMouseLeave={handleMouseLeave}
            style={{
              height: 'auto',
              background: selectColor == value.nameEn && value.colorHex,
              borderRadius: '10px',
              margin: '4px',
              boxSizing: 'border-box',
              boxShadow:
                selectColor == value.nameEn
                  ? '0px 5px 5px rgba(0, 0, 0, 0.25)'
                  : '0px 1px 2px rgba(0, 0, 0, 0.25)'
            }}
            onClick={() => {
              setSelectColor(value.nameEn)
              onSelect(value.nameEn)
            }}
          >
            {value.nameTh}
          </Button>
        )
      })}
    </div>
  )
}

ProductButtonColor.defaultProps = {
  colorProduct: [],
  onSelect: () => {},
  id: '',
  onClick: () => {},
  isStickyMenuMobile: false
}

ProductButtonColor.propTypes = {
  colorProduct: propTypes.array,
  onSelect: propTypes?.func,
  id: propTypes.string,
  onClick: propTypes?.func,
  isStickyMenuMobile: propTypes?.bool
}

export default ProductButtonColor
