import React from 'react'
import Fade from 'react-reveal/Fade'
import HeadShake from 'react-reveal/HeadShake'
// import styled from 'styled-components'

import { getTelFormatWithCountryCode } from 'static/utils'
import { TEL } from 'static/config/variables'

import './style.scss'

const PurchaseButtonGroup = props => {
  const getCallText = () => {
    if (props.isShowCall)
      return (
        <a
          id="m-tel-modal"
          className="call-modal"
          href={`tel:${getTelFormatWithCountryCode(TEL)}`}
          title={TEL}
        >
          โทรสอบถามรายละเอียดเพิ่มเติม คลิก!
        </a>
      )
  }

  const { isFanPage, classNameFanRemark } = props
  return (
    <Fade delay={200}>
      <div className={`purchase-line ${props.isNoBorder ? ' border-none' : ''}`}>
        <p className="upper-text">
          ตอบเร็ว มีเจ้าหน้าที่ดูแลจนกว่าพวงหรีดถึงงาน <br /> พร้อมจัดทำทันทีหลังโอนเงิน
        </p>
        <HeadShake delay={1000}>
          <div className="purchase-button">
            <a
              href="https://lin.ee/6Bs20SI?tanjai=B2"
              target="_blank"
              rel="noopener noreferrer"
              id={props.buttonId}
              title="@tanjai.co"
            >
              <img
                src="https://d3u85r51ph21nf.cloudfront.net/static/line.png"
                width={27}
                className="mr-2"
                alt="@tanjai.co"
              />
              <span>แชท + ซื้อพวงหรีด ผ่าน LINE</span>
            </a>
          </div>
        </HeadShake>
        {isFanPage ? (
          <p className={`lower-text fan-remark ${classNameFanRemark ? classNameFanRemark : ''}`}>
            *พัดลมบางพื้นที่มีค่าจัดส่ง โปรดเช็คค่าส่งกับเจ้าหน้าที่ <br />
            **จำนวน ขนาด และชนิดดอกไม้มีการผันแปรตามฤดูกาล
            <span>ทางร้านสงวนสิทธิ์ในการเปลี่ยนแปลงตามความเหมาะสมโดยจะคัดสรรสิ่งที่ดีที่สุด</span>
            <span>เพื่อไม่ให้คุณภาพของสินค้าโดยรวมเสียไป</span>
          </p>
        ) : (
          <p className="lower-text">
            *จำนวน ขนาด และชนิดดอกไม้มีการผันแปรตามฤดูกาล
            <span>ทางร้านสงวนสิทธิ์ในการเปลี่ยนแปลงตามความเหมาะสมโดยจะคัดสรรสิ่งที่ดีที่สุด </span>
            <span>เพื่อไม่ให้คุณภาพของสินค้าโดยรวมเสียไป</span>
          </p>
        )}
        {getCallText()}
      </div>
      {/* <div className="purchase-line desktop">
        <DesktopWrapper>
          <ButtonGroup>
            <div>
              <p className="upper-text">
                ตอบเร็ว มีเจ้าหน้าที่ดูแลจนกว่าพวงหรีดถึงงาน <br /> พร้อมจัดทำทันทีหลังโอนเงิน
              </p>
              <HeadShake delay={750}>
                <div className="purchase-button">
                  <a
                    href="https://lin.ee/6Bs20SI?tanjai=B2"
                    target="_blank"
                    rel="noopener noreferrer"
                    id={props.buttonId}
                    title="@tanjai.co"
                  >
                    <img
                      src="https://d3u85r51ph21nf.cloudfront.net/static/line.png"
                      width={18}
                      height={18}
                      className="mr-2"
                      alt="@tanjai.co"
                    />
                    <span>แชท + สั่งพวงหรีด{watName ? watName : ' ผ่าน LINE'}</span>
                  </a>
                  {isFanPage ? (
                    <p className="fan-remark-desktop">
                      *จำนวน ขนาด และชนิดดอกไม้มีการผันแปรตามฤดูกาล ทางร้านสงวนสิทธิ์
                      ในการเปลี่ยนแปลงตามความเหมาะสมโดยจะคัดสรรสิ่งที่ดีที่สุด
                      เพื่อไม่ให้คุณภาพของสินค้าโดยรวมเสียไป
                    </p>
                  ) : (
                    ''
                  )}
                </div>
              </HeadShake>
            </div>
            <div>
              <img
                src="https://d3u85r51ph21nf.cloudfront.net/static/qr.png"
                alt="@tanjai.co"
                width="75"
                height="75"
              />
            </div>
          </ButtonGroup>
        </DesktopWrapper>
        <p className="add-line">
          พิมพ์ค้นหาเพิ่มเพื่อน{' '}
          <a href="https://lin.ee/6Bs20SI?tanjai=B3" target="_blank" rel="noopener noreferrer">
            @tanjai.co
          </a>{' '}
          หรือสแกน QR Code เพื่อติดต่อเรา
        </p>
        {isShowCall && (
          <button id="d-tel-modal" className="call-modal" onClick={() => onToggleTel()}>
            โทรสอบถามรายละเอียดเพิ่มเติม คลิก!
          </button>
        )}
        {isShowTel && <small>{TEL}</small>}
      </div> */}
    </Fade>
  )
}

export default PurchaseButtonGroup

PurchaseButtonGroup.defaultProps = {
  buttonLabelMobile: 'สั่งพวงหรีดกับเจ้าหน้าที่',
  isNoBorder: false,
  buttonId: '',
  isShowCall: false,
  isShowTel: false,
  onToggleTel: () => {}
}

// const DesktopWrapper = styled.div`
//   display: inline-block;
//   padding: 15px;
//   border: 1px solid rgba(0, 0, 0, 0.1);
//   border-radius: 5px;
//   background: #ffffff;
// `

// const ButtonGroup = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   div:first-child {
//     margin-right: 10px;
//   }
//   .upper-text {
//     font-size: 17px;
//   }
//   .purchase-button {
//     a {
//       min-width: 360px;
//       font-size: 22px;
//       padding-top: 8px;
//       &:hover {
//         text-decoration: none;
//       }
//     }
//   }
// `
