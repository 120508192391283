import React, { Component } from 'react'

import './style.scss'

export default class CustomFAQ extends Component {
  state = {
    isOpenFAQ: false
  }
  render() {
    const { isOpenFAQ } = this.state
    const { title, answer } = this.props
    return (
      <a className={`custom-faq ${isOpenFAQ ? 'open' : ''}`}>
        <div
          className={`faq-title ${isOpenFAQ ? 'open' : ''}`}
          onClick={() => {
            this.setState({ isOpenFAQ: !this.state.isOpenFAQ })
          }}
          itemScope
          itemProp="mainEntity"
          itemType="https://schema.org/Question"
        >
          <p>{title}</p>
          <div>
            <img
              src="https://d3u85r51ph21nf.cloudfront.net/static/arrow_gold.png"
              alt="arrow down"
              width="11"
              height="7"
            />
          </div>
        </div>
        {isOpenFAQ && (
          <div
            className="faq-body"
            itemScope
            itemProp="acceptedAnswer"
            itemType="https://schema.org/Answer"
          >
            <p itemprop="text">{answer}</p>
          </div>
        )}
      </a>
    )
  }
}
