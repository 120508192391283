import React from 'react'
import Fade from 'react-reveal/Fade'
import { FacebookProvider, Page } from 'react-facebook'

import './style.scss'

const REVIEW_IMG_LIST = [
  {
    src: 'https://d3u85r51ph21nf.cloudfront.net/chat-reviews/chat_review_01-min.jpg',
    alt: 'สั่งพวงหรีด พวงหรีดดอกไม้สด พวงหรีดพัดลม'
  },
  {
    src: 'https://d3u85r51ph21nf.cloudfront.net/chat-reviews/chat_review_03-min.jpg',
    alt: 'สั่งพวงหรีด พวงหรีดดอกไม้สด พวงหรีดพัดลม'
  },
  {
    src: 'https://d3u85r51ph21nf.cloudfront.net/chat-reviews/chat_review_04-min.jpg',
    alt: 'สั่งพวงหรีด พวงหรีดดอกไม้สด พวงหรีดพัดลม'
  },
  {
    src: 'https://d3u85r51ph21nf.cloudfront.net/chat-reviews/chat_review_05-min.jpg',
    alt: 'สั่งพวงหรีด พวงหรีดดอกไม้สด พวงหรีดพัดลม'
  },
  {
    src: 'https://d3u85r51ph21nf.cloudfront.net/chat-reviews/chat_review_07-min.jpg',
    alt: 'สั่งพวงหรีด พวงหรีดดอกไม้สด พวงหรีดพัดลม'
  },
  {
    src: 'https://d3u85r51ph21nf.cloudfront.net/chat-reviews/chat_review_08-min.jpg',
    alt: 'สั่งพวงหรีด พวงหรีดดอกไม้สด พวงหรีดพัดลม'
  },
  {
    src: 'https://d3u85r51ph21nf.cloudfront.net/chat-reviews/chat_review_09-min.jpg',
    alt: 'สั่งพวงหรีด พวงหรีดดอกไม้สด พวงหรีดพัดลม'
  },
  {
    src: 'https://d3u85r51ph21nf.cloudfront.net/chat-reviews/chat_review_10-min.jpg',
    alt: 'สั่งพวงหรีด พวงหรีดดอกไม้สด พวงหรีดพัดลม'
  },
  {
    src: 'https://d3u85r51ph21nf.cloudfront.net/chat-reviews/chat_review_11-min.jpg',
    alt: 'สั่งพวงหรีด พวงหรีดดอกไม้สด พวงหรีดพัดลม'
  },
  {
    src: 'https://d3u85r51ph21nf.cloudfront.net/chat-reviews/chat_review_12-min.jpg',
    alt: 'สั่งพวงหรีด พวงหรีดดอกไม้สด พวงหรีดพัดลม'
  }
]

const Review = () => {
  return (
    <Fade delay={300}>
      <div className="review" id="review-chat">
        <div className="container review-chat-facebook">
          <div className="review-chat-wrapper">
            <h3>
              รีวิวจากลูกค้า
            </h3>
            {/* <Divider /> */}
            <div className="img-list-wrapper">
              <div className="img-list">
                {REVIEW_IMG_LIST.map((item, index) => {
                  return (
                    <div key={index} className="img-item chat">
                      <img src={item.src} alt={item.alt} />
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
          <div className="facebook-wrapper">
            <h3>
              รีวิวจาก Facebook
            </h3>
            {/* <Divider /> */}
            <div className="facebook-box">
              <div className="d-md-none">
                <FacebookProvider appId="198714437399062">
                  <Page
                    href="https://www.facebook.com/tanjai.co/"
                    tabs="timeline"
                    height="280px"
                    smallHeader={true}
                  />
                </FacebookProvider>
              </div>
              <div className="d-none d-md-block">
                <FacebookProvider appId="198714437399062">
                  <Page
                    href="https://www.facebook.com/tanjai.co/"
                    tabs="timeline"
                    // height="280px"
                    // smallHeader={true}
                  />
                </FacebookProvider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fade>
  )
}

export default Review
