import React, { useState, useEffect } from 'react'

// import Divider from 'src/components/Divider'

import './style.scss'

const AllLinksList = props => {
  const { watsList } = props
  const [list, setList] = useState({})

  useEffect(() => {
    let bangkok = []
    let north = []
    let northeast = []
    let central = []
    let east = []
    let west = []
    let south = []
    let other = []
    watsList.edges.map(item => {
      const group = item.node.frontmatter.group
      switch (group) {
        case 'bangkok':
          bangkok = [...bangkok, { ...item.node.frontmatter }]
          break
        case 'north':
          north = [...north, { ...item.node.frontmatter }]
          break
        case 'northeast':
          northeast = [...northeast, { ...item.node.frontmatter }]
          break
        case 'central':
          central = [...central, { ...item.node.frontmatter }]
          break
        case 'east':
          east = [...east, { ...item.node.frontmatter }]
          break
        case 'west':
          west = [...west, { ...item.node.frontmatter }]
          break
        case 'south':
          south = [...south, { ...item.node.frontmatter }]
          break
        default:
          other = [...other, { ...item.node.frontmatter }]
          break
      }
      return ''
    })
    const list = {
      bangkok,
      north,
      northeast,
      central,
      east,
      west,
      south
    }

    setList(list)
  }, [watsList])

  const sortingAlphabetically = (a, b) => {
    if (a.name > b.name) {
      return 1
    }
    if (b.name > a.name) {
      return -1
    }
    return 0
  }

  const sortingByPostition = (a, b) => {
    if (Number(a.linkPosition) > Number(b.linkPosition)) {
      return 1
    }
    if (Number(b.linkPosition) > Number(a.linkPosition)) {
      return -1
    }
    return 0
  }

  const getListWithSorting = zone => {
    if (list[zone]) {
      let tmp = []
      let has_position = []
      let no_position = []
      list[zone].map(item => {
        if (item.linkPosition) has_position = [...has_position, item]
        else no_position = [...no_position, item]
        return ''
      })

      tmp = has_position.sort(sortingByPostition)
      tmp = [...tmp, ...no_position.sort(sortingAlphabetically)]

      return tmp
    }
  }

  return (
    <div className="all-links-list">
      <div className="container">
        <h3>พื้นที่จัดส่งพวงหรีด</h3>
        {/* <Divider /> */}
        <div className="list-wrapper">
          {list.bangkok && (
            <div className="list-item full-width">
              <h4>กรุงเทพ</h4>
              <ul>
                {getListWithSorting('bangkok').map((item, index) => {
                  return (
                    <li key={index}>
                      <a href={item.path}>{`พวงหรีด${item.name}${item.name2 ? item.name2 : ''}`}</a>
                    </li>
                  )
                })}
              </ul>
            </div>
          )}
          {list.northeast && (
            <div className="list-item">
              <h4>ภาคอีสาน</h4>
              <ul>
                {getListWithSorting('northeast').map((item, index) => {
                  return (
                    <li key={index}>
                      <a href={item.path}>{`พวงหรีด${item.name}${item.name2 ? item.name2 : ''}`}</a>
                    </li>
                  )
                })}
              </ul>
            </div>
          )}
          {list.south && (
            <div className="list-item">
              <h4>ภาคใต้</h4>
              <ul>
                {getListWithSorting('south').map((item, index) => {
                  return (
                    <li key={index}>
                      <a href={item.path}>{`พวงหรีด${item.name}${item.name2 ? item.name2 : ''}`}</a>
                    </li>
                  )
                })}
              </ul>
            </div>
          )}
          {list.central && (
            <div className="list-item">
              <h4>ภาคกลาง</h4>
              <ul>
                {getListWithSorting('central').map((item, index) => {
                  return (
                    <li key={index}>
                      <a href={item.path}>{`พวงหรีด${item.name}${item.name2 ? item.name2 : ''}`}</a>
                    </li>
                  )
                })}
              </ul>
            </div>
          )}
          {list.north && (
            <div className="list-item">
              <h4>ภาคเหนือ</h4>
              <ul>
                {getListWithSorting('north').map((item, index) => {
                  return (
                    <li key={index}>
                      <a href={item.path}>{`พวงหรีด${item.name}${item.name2 ? item.name2 : ''}`}</a>
                    </li>
                  )
                })}
              </ul>
            </div>
          )}
          {list.east && (
            <div className="list-item">
              <h4>ภาคตะวันออก</h4>
              <ul>
                {getListWithSorting('east').map((item, index) => {
                  return (
                    <li key={index}>
                      <a href={item.path}>{`พวงหรีด${item.name}${item.name2 ? item.name2 : ''}`}</a>
                    </li>
                  )
                })}
              </ul>
            </div>
          )}
          {list.west && (
            <div className="list-item">
              <h4>ภาคตะวันตก</h4>
              <ul>
                {getListWithSorting('west').map((item, index) => {
                  return (
                    <li key={index}>
                      <a href={item.path}>{`พวงหรีด${item.name}${item.name2 ? item.name2 : ''}`}</a>
                    </li>
                  )
                })}
              </ul>
            </div>
          )}
          {list.other && (
            <div className="list-item">
              <h4>อื่นๆ</h4>
              <ul>
                {getListWithSorting('other').map((item, index) => {
                  return (
                    <li key={index}>
                      <a href={item.path}>{`พวงหรีด${item.name}${item.name2 ? item.name2 : ''}`}</a>
                    </li>
                  )
                })}
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default AllLinksList
